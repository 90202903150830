define("merlin/controllers/terms", ["exports", "ember", "merlin/config/environment"], function (exports, _ember, _merlinConfigEnvironment) {
  exports["default"] = _ember["default"].Controller.extend({

    apex: _ember["default"].computed("", function () {
      return _merlinConfigEnvironment["default"].apex;
    }),

    dbat: _ember["default"].computed("", function () {
      return _merlinConfigEnvironment["default"].dbat;
    }),

    ar: _ember["default"].computed("", function () {
      return _merlinConfigEnvironment["default"].ar;
    })
  });
});