define("merlin/routes/terms", ["exports", "ember"], function (exports, _ember) {
  var Route = _ember["default"].Route;
  var inject = _ember["default"].inject;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  exports["default"] = Route.extend({
    ////////////////////////////
    ////////  Services  ////////
    ////////////////////////////

    authenticator: inject.service(),
    sessionManager: inject.service(),

    ///////////////////////////
    ////////  Methods  ////////
    ///////////////////////////

    beforeModel: function beforeModel() {
      var _this = this;

      var sessionManager = get(this, "sessionManager");

      if (get(this, "session.content.isAuthenticated")) {
        var _ret = (function () {
          var uid = get(_this, "session.content.uid");
          return {
            v: _this._userTosAccepted(uid).then(function (accepted) {
              if (accepted) {
                return _this._userIsACoach(uid).then(function (isCoach) {
                  if (isCoach) {
                    sessionManager.smartTransition(_this, "/coach");
                  } else {
                    sessionManager.smartTransition(_this, "/videos");
                  }
                });
              } else {
                return true;
              }
            })["catch"](function (error) {
              _this.errorHandler.throwIt(error);
            })
          };
        })();

        if (typeof _ret === "object") return _ret.v;
      } else {
        this.transitionTo("login");
      }
    },

    _userTosAccepted: function _userTosAccepted(uid) {
      return this.store.findRecord("user", uid).then(function (user) {
        return get(user, "tos_accepted");
      });
    },

    _userIsACoach: function _userIsACoach(uid) {
      return this.store.findRecord("user", uid).then(function (user) {
        return get(user, "role") === "coach";
      });
    },

    actions: {
      error: function error(_error) {
        return this.errorHandler.log(_error);
      },

      reject: function reject() {
        var _this2 = this;

        get(this, "sessionManager").unload();

        /*true*/get(this, "authenticator").safelyCloseSession().then(function () {
          get(_this2, "router").transitionTo("login");
        })["catch"](function (error) {
          _this2.errorHandler.throwIt(error);
        });
      },

      accept: function accept() {
        var _this3 = this;

        var uid = get(this, "session.content.uid");
        var e = this.errorHandler;
        var sessionManager = get(this, "sessionManager");

        this.store.findRecord("user", uid).then(function (user) {
          set(user, "tos_accepted", true);

          var ref = user.ref();
          return ref.update({
            tos_accepted: true
          });
        }).then(function () {
          return _this3._userTosAccepted(uid);
        }).then(function (accepted) {
          if (accepted) {
            return _this3._userIsACoach(uid);
          } else {
            e.throwIt({
              message: "Something went wrong accepting your terms of service."
            });
          }
        }).then(function (isCoach) {
          if (isCoach) {
            sessionManager.smartTransition(_this3, "/coach");
          } else {
            sessionManager.smartTransition(_this3, "/videos");
          }
        })["catch"](function (error) {
          e.throwIt(error);
        });
      }
    }
  });
});