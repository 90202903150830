define('merlin/controllers/signup', ['exports', 'ember', 'merlin/config/environment', 'ember-ajax/errors', 'merlin/validations/signup', 'merlin/validations/code'], function (exports, _ember, _merlinConfigEnvironment, _emberAjaxErrors, _merlinValidationsSignup, _merlinValidationsCode) {
	var Controller = _ember['default'].Controller;
	var inject = _ember['default'].inject;
	var computed = _ember['default'].computed;
	var get = _ember['default'].get;
	var set = _ember['default'].set;
	var RSVP = _ember['default'].RSVP;
	exports['default'] = Controller.extend({

		////////////////////////////
		////////  Services  ////////
		////////////////////////////

		authenticator: inject.service(),
		firebaseWrapper: inject.service(),
		ajax: inject.service(),

		//////////////////////////////////////
		////////  Default Properties  ////////
		//////////////////////////////////////

		queryParams: ['code', 'role', 'fname', 'lname', 'email'],

		code: '',
		role: '',
		fname: '',
		lname: '',
		email: '',

		dbat: _merlinConfigEnvironment['default'].dbat ? true : false,
		apex: _merlinConfigEnvironment['default'].apex ? true : false,
		ar: _merlinConfigEnvironment['default'].ar ? true : false,

		isLoading: false,
		invalidCode: false,

		SignupValidations: _merlinValidationsSignup['default'],

		signupFormOptions: computed("fname", "lname", "email", function () {
			return {
				fields: [{
					id: "nameFirst",
					label: "FIRST NAME"
				}, {
					id: "nameLast",
					label: "LAST NAME"
				}, {
					id: "email",
					label: "EMAIL",
					autocapitalize: "off",
					spellcheck: false,
					autocorrect: "off"
				}, {
					id: "password",
					label: "PASSWORD",
					type: "password",
					focus: true,
					autocapitalize: "off",
					spellcheck: false,
					autocorrect: "off"
				}, {
					id: "confirmPassword",
					label: "CONFIRM PASSWORD",
					type: "password",
					autocapitalize: "off",
					spellcheck: false,
					autocorrect: "off"
				}],

				data: {
					nameFirst: get(this, "fname") || "",
					nameLast: get(this, "lname") || "",
					email: get(this, "email") || "",
					password: "",
					confirmPassword: ""
				},

				buttons: [{
					id: "signup",
					label: "Sign up"
				}],

				serverErrors: {}
			};
		}),

		restartedSignup: false,

		CodeValidations: _merlinValidationsCode['default'],

		codeFormOptions: {
			fields: [{
				id: "inviteCode",
				label: "INVITATION CODE",
				focus: true,
				autocapitalize: "off",
				spellcheck: false,
				autocorrect: "off"
			}],

			data: {
				inviteCode: ""
			},

			buttons: [{
				id: "proceed",
				label: "Proceed to Signup"
			}],

			serverErrors: {}
		},

		///////////////////////////
		////////  Methods  ////////
		///////////////////////////

		trySubmission: function trySubmission(data) {
			var _this = this;

			var sharedData = {};

			sharedData.nameFirst = get(data, 'nameFirst');
			sharedData.nameLast = get(data, 'nameLast');
			sharedData.email = get(data, 'email').toLowerCase();
			sharedData.password = get(data, 'password');
			sharedData.code = get(this, 'code');

			// Activate the loading animation.
			set(this, "isLoading", true);

			// Start the signup process.
			// I first assume that you are a returning user who has already completed signup.
			// And if not, I recursively build your data until you finish the full signup process.
			this.signup(sharedData).then(function () {
				for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
					args[_key] = arguments[_key];
				}

				if (!_this.tools.isEmpty(args) && args.length > 0 && args[0] !== undefined) {
					var session = get(_this, "session");
					var organization = args.id;
					var pid = get(args, "personID");
					set(session, "organization", organization);
					set(session, "pid", pid);
				}

				return get(_this, "authenticator").safelyAuthenticate(sharedData.email, sharedData.password);
			}).then(function () {
				// Proceed to success.
				_this.transitionToRoute('success');
			})['catch'](function (error) {
				_this.errorHandler.throwIt(error);
			});
		},

		signup: function signup(sharedData) {
			var _this2 = this;

			return new RSVP.Promise(function (resolve, reject) {
				_this2.tryToFinishSignup(sharedData).then(function () {
					resolve.apply(undefined, arguments);
				})['catch'](function (error) {
					// If the provided email is invalid, show error ui.
					if (error.code === "auth/invalid-email") {
						set(_this2, "signupFormOptions.serverErrors", { key: "email", validation: "Invalid Email" });
						set(_this2, "isLoading", false);
					} else if (error.code === "auth/user-not-found") {
						// If the firebase account doesn’t exist, create it.
						return _this2.createNewAccount(sharedData).then(function () {
							return _this2.signup(sharedData);
						}).then(function () {
							resolve();
						})['catch'](function (error) {
							reject(error);
						});
					} else if (error.code === "custom/no-record-found") {
						// If the user record doesn’t exist, create it.
						return _this2.createNewUser(sharedData).then(function () {
							return _this2.signup(sharedData);
						}).then(function () {
							resolve();
						})['catch'](function (error) {
							reject(error);
						});
					} else if (error.code === "custom/invalid-code") {
						set(_this2, "invalidCode", true);
						set(_this2, "isLoading", false);
					} else if (error.code === "auth/wrong-password") {
						set(_this2, "signupFormOptions.serverErrors", { key: "password", validation: "Incorrect Password" });
						set(_this2, "restartedSignup", true);
						set(_this2, "isLoading", false);
					} else if ((0, _emberAjaxErrors.isNotFoundError)(error)) {
						set(_this2, "invalidCode", true);
						set(_this2, "code", "");
						set(_this2, "codeFormOptions.data.inviteCode", "");
						set(_this2, "isLoading", false);
					} else {
						reject(error);
					}
				});
			});
		},

		tryToFinishSignup: function tryToFinishSignup(sharedData) {
			var _this3 = this;

			// Check to see if this account exits.
			return new RSVP.Promise(function (resolve, reject) {
				get(_this3, 'authenticator').safelyAuthenticate(sharedData.email, sharedData.password).then(function (data) {
					// Set the UID for use later.
					sharedData.uid = data.uid;
					// Check to see if there is user data.
					return get(_this3, "store").findRecord("user", sharedData.uid);
				}).then(function (user) {
					var authorization = user.get('authorizations').objectAt(0);

					// If signup is already complete, proceed to success.
					// This will need to get fixed when we start allowing multiple organizations.
					if (!_this3.tools.isEmpty(authorization)) {
						resolve();
					} else {
						// Otherwise, attempt to complete signup.
						return _this3.acceptInvitation(sharedData);
					}
				}).then(function () {
					resolve.apply(undefined, arguments);
				})['catch'](function (error) {
					// Transform a no record found error into something useful.
					var noUserMessage = "no record was found at " + _merlinConfigEnvironment['default'].firebase.databaseURL + "/users/" + sharedData.uid;

					if (error.message === noUserMessage) {
						error.code = "custom/no-record-found";
					}

					if (error.message === "The invitation is no longer valid.") {
						error.code = "custom/invalid-code";
					}

					reject(error);
				});
			});
		},

		createNewAccount: function createNewAccount(sharedData) {
			// Create a new Firebase account.
			return get(this, 'firebaseWrapper').createAccount(sharedData.email, sharedData.password);
		},

		createNewUser: function createNewUser(sharedData) {
			var _this4 = this;

			return get(this, 'authenticator').safelyAuthenticate(sharedData.email, sharedData.password).then(function () {

				var userObject = {
					id: sharedData.uid,
					fname: sharedData.nameFirst,
					lname: sharedData.nameLast,
					email: sharedData.email,
					tos_accepted: false,
					email_confirmed: false,
					showMeNews: true
				};
				var newUser = get(_this4, "store").createRecord('user', userObject);

				var userUpdateObject = {
					fname: sharedData.nameFirst,
					lname: sharedData.nameLast,
					email: sharedData.email,
					tos_accepted: false,
					email_confirmed: false,
					showMeNews: true
				};

				var ref = newUser.ref();
				return ref.update(userUpdateObject);
				// return newUser.save();
			});
		},

		acceptInvitation: function acceptInvitation(sharedData) {

			var data = {
				email: sharedData.email,
				userId: sharedData.uid
			};

			return get(this, 'ajax').request(_merlinConfigEnvironment['default'].falconer + "invite/" + sharedData.code + "/accept", {
				method: 'POST',
				data: data
			});
		},

		///////////////////////////
		////////  Actions  ////////
		///////////////////////////

		actions: {
			submittedCode: function submittedCode() {
				var data = arguments[1];
				var code = get(data, "inviteCode");
				set(this, "code", code);
			},

			submittedSignup: function submittedSignup() {
				this.trySubmission(arguments[1]);
			}
		}
	});
});